import Img from "../assets/images/header_logo.png";
import React from "react";
// import { useQueryParam, NumberParam, StringParam } from "use-query-params";

const ThankYouPage = ({ location }) => {
  // console.log(location, "sadF");
  const params = new URLSearchParams(location.search);
  const parameter1 = params.get("form");
  // console.log("object", parameter1);
  return (
    <div className="bg-secondary-linearGradient-500 h-screen">
      <div className=" max-w-6xl m-auto overflow-hidden h-full">
        <div className=" h-full flex justify-center items-center flex-col px-4">
          <div>
            <img src={Img} className="w-28 h-36" alt="icon" />
          </div>
          <div className="text-4.5xl lg:text-6xl font-bold text-secondary-golden-500">
            Thank You !!!
          </div>
          <div className="text-3xl font-semibold text-center items-center text-gray-50">
            {parameter1 === "contact"
              ? "We will Contact you soon"
              : "for your feedback"}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ThankYouPage;
